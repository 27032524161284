exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-capabilities-tsx": () => import("./../../../src/pages/Capabilities.tsx" /* webpackChunkName: "component---src-pages-capabilities-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/ContactUs.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-details-tsx": () => import("./../../../src/pages/InsightsDetails.tsx" /* webpackChunkName: "component---src-pages-insights-details-tsx" */),
  "component---src-pages-insights-index-tsx": () => import("./../../../src/pages/Insights/index.tsx" /* webpackChunkName: "component---src-pages-insights-index-tsx" */),
  "component---src-pages-our-clients-tsx": () => import("./../../../src/pages/OurClients.tsx" /* webpackChunkName: "component---src-pages-our-clients-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/Services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

